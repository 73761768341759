<template>
  <BaseCard
    title="datos de la oferta"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <OfferForm
      ref="offer-form"
      v-model="offer"
      hide-code
    />
  </BaseCard>
</template>

<script>
import ApiRestService from '@/api/base-api'
import OffersApi from '@/api/offers-api'
import OfferForm from '@/components/offers/form/OfferForm.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: {
    BaseCard,
    OfferForm,
  },
  data() {
    return {
      loading: false,
      offer: {},
    }
  },
  computed: {
    clientId() {
      return this.$route.query['client-id'] || null
    },
    projectId() {
      return this.$route.query['project-id'] || null
    },
  },
  mounted() {
    this.loadClient()
    this.loadProject()
  },
  methods: {
    async loadClient() {
      if (!this.clientId) {
        return
      }

      try {
        this.loading = true
        const response = await ApiRestService.get(this.clientId, this.$data.$constants.RESOURCES.RESOURCE_CLIENTS)
        this.offer = { ...this.offer, client: response.data }
      } finally {
        this.loading = false
      }
    },
    async loadProject() {
      if (!this.projectId) {
        return
      }

      this.loading = true
      const response = await ApiRestService.get(this.projectId, this.$data.$constants.RESOURCES.RESOURCE_PROJECTS)
      this.offer = { ...this.offer, project: response.data }
      this.loading = false
    },
    async handleSaveButtonClick() {
      const valid = await this.$refs['offer-form'].validateForm()
      if (!valid) {
        return
      }

      this.loading = true
      try {
        const { documentation } = this.$refs['offer-form'].getDocumentationData()
        const response = await OffersApi.create({ ...this.offer, documentation })
        const newOffer = response.data
        await this.$router.replace({ name: 'viewOffer', params: { id: newOffer.id } })
      } catch (error) {
        this.handleError(error)
      }

      this.loading = false
    },
    handleError(error) {
      if (error.response.data?.code) {
        this.$refs['offer-form'].focusCodeError()
        this.$toast.error(error.response.data.code[0])
      } else {
        this.$toast.error('Ocurrio un error al crear la oferta. Por favor inténtelo de nuevo mas tarde.')
      }
    },
  },
}
</script>

<style scoped>
</style>
